.view-details {
  font-family: Poppins, sans-serif;
  margin: 20px;
margin-left: 0px;
  
}

h1, h2, h3 {
  color: #333;
}

.warranty-terms {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.column {
  width: 48%;
  margin-left: -10px;
  padding: 15px;
  border-radius: 8px;
 
}

.column h2 {
  margin-bottom: 10px;
  

  font-size: 16px;
  font-weight: bold;
  text-align: left;
}

.column ul {
  list-style: none;
  padding: 0;
}

.column li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
 
}



.column img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.how-we-work {
  margin-top: 30px;
  
}

.how-we-work h2{
  font-size: 48px;
}

.work-step {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.text-content {
  width: 50%;
  padding-right: 50px;
}

.text-content  p {
  font-size: large;
}
.image-content {
  width: 40%;
  float: right;
  padding-right: -50px !important;
}

.image-content img {
  width: 250px;
  height: 250px;
  padding-left: 70px;
  padding-right: 0px;
  
}

.benefits {
  background: #eef9f4;
  padding: 15px;
  
  margin-top: 20px;
}

.benefits ul {
  list-style: none;
  padding: 0;
}

.benefits li {
  margin-bottom: 10px;
  padding-left: 20px;
  position: relative;
}



.banner {
  margin-top: 30px ;
  text-align: center;
}

.banner img {
  width: 80%;
  display: block; /* Ensures it behaves like a block-level element */
  float: left; /* Aligns the image to the left */
  padding-bottom: 0px!important;
  
  padding-bottom: 10px !important;
}

.banner1_mobile_view {
  display: none;
}





/* Container for each work step */
.how-we-work .work-step {
  display: flex;
  flex-wrap: wrap;  /* Ensures text and image are responsive */
  gap: 50px;  /* Adjusts the space between the text and image */
  margin-bottom: 30px;  /* Space between each work step */
}

.how-we-work .text-content {
  flex: 1;  /* Takes available space */
}

.how-we-work .image-content {
  flex-shrink: 0;  /* Prevents image from shrinking */
  max-width: 100%;  /* Ensures image stays within container size */
}

.how-we-work img {
  width: 50%;  /* Ensures image fits within its container */
  height: auto;  /* Keeps the image ratio intact */
}
.banner1_web_view {
  padding-bottom: 30px !important;
   text-align: center; /* Center-align the content */
  
 }
 .banner1_web_view {
  display: block;
}


@media screen and (max-width: 750px) {
  .view-details {
    font-family: Poppins, sans-serif;
    margin: 20px;
  margin-left: 0px;

  margin-right: 0px;
    
  }
  .how-we-work {
    margin-top: 10px;
  }

  .warranty-terms {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    gap: -5px !important; /* Add spacing between rows */
  }

  .column {
    width: 100%; /* Make each column take full width */
  }

  .how-we-work h2 {
    font-size: 1.5rem; /* Reduce the heading size */
  }

  .work-step {
    
    gap: 0.5rem;
    display: flex;
    align-items: flex-start;
    flex-direction: row; /* Keep text and image side by side */
  }

  .text-content {
    flex: 7 !important; /* 70% width */
    margin-right: 0rem; /* Reduce gap for smaller screens */
  }

  .text-content h3 {
    font-size: 16px; /* Smaller heading */
  }

  .text-content  p {
    font-size: 12px; /* Smaller paragraph */

  }

  .image-content{
    
    flex: 3 !important; /* 30% width */
    text-align: center; /* Center the image horizontally */
  }

  .image-content img {

   
    width: 100px !important; /* Ensure it uses the full width of its container */
    height: 100px!important; /* Maintain aspect ratio */
    padding-left: 5px !important;
    margin-top: 20px;
    
  }

 /* Benefits Section */
.benefits {
  background: #eef9f4;
  padding: 15px;
  margin-top: 20px;
  max-width: 800px; /* Constrain the width */
  margin-left: auto;
  margin-right: auto; /* Center the section horizontally */
  
  
}

.benefits ul {
  list-style: none;
  padding: 0;
}

.benefits li {
  margin-bottom: 10px;
  padding-left: 20px;
  position: relative;
}



/* Banner Section */
.banner {
  margin-top: 10px !important;
  text-align: center; /* Center-align the content */
 
}
.banner1_mobile_view {
 padding-bottom: 30px !important;
  text-align: center; /* Center-align the content */
 
}
.banner1_mobile_view {
  display: block;
}
.banner1_web_view {
  display: none;
}


.banner img {
  width: 100%;
  max-width: 800px; /* Constrain the width */
  margin-left: auto;
  margin-right: auto; /* Center the image */
  margin-top: 0px;
   
   
  

}

}